import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "views/Dashboard";
import NotFound from "views/NotFound";
import Welcome from "views/Welcome";
import LoadingPage from "views/LoadingPage";
const Banners = React.lazy(() => import("views/Banners"));
const WhyVivactiv = React.lazy(() => import("views/WhyVivactiv"));
const Benefits = React.lazy(() => import("views/Benefits"));
const Ingredients = React.lazy(() => import("views/Ingredients"));
const Products = React.lazy(() => import("views/Products"));
const Questions = React.lazy(() => import("views/Questions"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Welcome />}></Route>
        <Route
          path="banners"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Banners />
            </React.Suspense>
          }
        />
        <Route
          path="why-vivactiv"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <WhyVivactiv />
            </React.Suspense>
          }
        />
        <Route
          path="benefits"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Benefits />
            </React.Suspense>
          }
        />
        <Route
          path="ingredients"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Ingredients />
            </React.Suspense>
          }
        />
        <Route
          path="products"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Products />
            </React.Suspense>
          }
        />
        <Route
          path="questions"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Questions />
            </React.Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
