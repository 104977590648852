import "./App.css";

import { ThemeProvider } from "@mui/material/styles";
import useStore from "context";
import useTheme from "hooks/useTheme";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// import { useEffect, useState } from 'react';
import Routes from "routes";
import Loading from "views/LoadingPage";
import Login from "views/Login";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { activeTheme } = useTheme();
  const authentication = useStore((state) => state.authentication);

  if (authentication === undefined) {
    //  If store is not mounted yet
    return (
      <ThemeProvider theme={activeTheme}>
        <Loading />
      </ThemeProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={activeTheme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {authentication ? <Routes /> : <Login />}
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
