export const DEFAULT_IMAGE =
  "https://25cumbres.segib.org/wp-content/uploads/2016/09/ef3-placeholder-image-670x500.jpg";

export const BASE_URL = "https://www.api.vivactic.ludik.pe:8450";
export const STORE_NAME = "store-vivactiv";
export const VALID_URL_REGEX =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/;

export const BANNER_RECOMMENDED_DIMENSIONS = { width: "1920", height: "810" };
export const IMAGE_RECOMMENDED_DIMENSIONS = { width: "800", height: "800" };
export const SMALL_IMAGE_RECOMMENDED_DIMENSIONS = { width: "220", height: "220" };
export const ICON_RECOMMENDED_DIMENSIONS = { width: "60", height: "60" };
export const ICON_MOBILE_RECOMMENDED_DIMENSIONS = { width: "50", height: "50" };

export const NOT_FOUND_MESSAGE = "No se ha encontrado lo solicitado.";