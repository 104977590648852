/* eslint-disable react-hooks/exhaustive-deps */
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useStore from "context";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import LocalPharmacyOutlinedIcon from "@mui/icons-material/LocalPharmacyOutlined";
import styles from "./MenuSideBar.module.css";

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const CustomTab = styled(Tab)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 10,
  color: theme.palette.text.primary,
  minHeight: "auto",
}));

const MenuSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const isMenuExpanded = useStore((state) => state.isMenuExpanded);
  const logout = useStore((state) => state.logout);

  const menuItems = [
    {
      title: "Inicio",
      key: "dashboard",
      href: "/",
      value: 0,
      icon: <DashboardOutlinedIcon />,
    },
    {
      title: "Banners",
      key: "banners",
      href: "/banners",
      value: 1,
      icon: <ViewCarouselOutlinedIcon />,
    },
    {
      title: "¿Por qué Vivactiv?",
      key: "why-vivactiv",
      href: "/why-vivactiv",
      value: 2,
      icon: <ElectricBoltOutlinedIcon />,
    },
    {
      title: "Beneficios",
      key: "benefits",
      href: "/benefits",
      value: 3,
      icon: <HealthAndSafetyOutlinedIcon />,
    },
    {
      title: "¿Qué contiene?",
      key: "ingredients",
      href: "/ingredients",
      value: 4,
      icon: <LocalPharmacyOutlinedIcon />,
    },
    {
      title: "¿Dónde comprar?",
      key: "products",
      href: "/products",
      value: 5,
      icon: <AddLocationOutlinedIcon />,
    },
    {
      title: "Preguntas",
      key: "questions",
      href: "/questions",
      value: 6,
      icon: <ContactSupportOutlinedIcon />,
    },
  ];

  useEffect(() => {
    // change the activeMenuItem depending on the current pathname
    const pathName = location.pathname;
    const item = menuItems.find((item) => {
      // TODO: verify this warning
      const regexStr = `^${item.href}`;
      const regex = new RegExp(regexStr, "g");
      if (item.href !== "/") return regex.test(pathName); // "/" is not considered because wvery pathname starts with "/"
    });
    if (item) setActiveMenuItem(item.value);
  }, []);

  const handleChange = (event, newValue) => {
    setActiveMenuItem(newValue);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <ul className={`${styles.container}`}>
      <Tabs
        orientation="vertical"
        // variant="scrollable"
        value={activeMenuItem}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {menuItems.map((item, index) => (
          <Tooltip
            key={index}
            title={isMenuExpanded ? "" : item.title}
            placement="right-start"
          >
            <CustomTab
              icon={item.icon}
              label={isMenuExpanded ? item.title : ""}
              {...a11yProps(index)}
              sx={{
                justifyContent: `${isMenuExpanded ? "flex-start" : "center"}`,
              }}
              onClick={() => navigate(item.href)}
            />
          </Tooltip>
        ))}
      </Tabs>
      <Tooltip key={7} title={isMenuExpanded ? "" : "Salir"} placement="right-start">
        <CustomTab
          icon={<LogoutIcon />}
          label={isMenuExpanded ? "Salir" : ""}
          {...a11yProps(0)}
          sx={{
            justifyContent: `${isMenuExpanded ? "flex-start" : "center"}`,
            marginTop: "auto",
          }}
          onClick={handleLogout}
        />
      </Tooltip>
    </ul>
  );
};

export default MenuSideBar;
